import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		minHeight: '100vh',
		display: 'flex',
	},
  content: {
    ...theme.typography.fontPantonLight,
    ...theme.component.breadcrumbs,
    boxSizing: 'border-box',
    fontSize: '0.8rem',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    width: '100%'
	},
  linkItem: {
      ...theme.typography.fontPantonLight,
      ...theme.component.breadcrumbs,
      fontSize: '0.8rem',
      textDecoration: 'none'
  },
  separator: {
    paddingTop: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 0,
  }
}));

function CrumbRouteTwo(props) {

  const {
    project,

  } = props;
  
  const classes = useStyles();

  return (
    <div className={classes.content}>
    <Route     
      path="/"
      render={(props) =>  (
        <a href={process.env.REACT_APP_MY_LIXANI_URL}  className={classes.linkItem} >MyLixani</a>
      )}
    />
    <Route   
      path='/:projectKey' 
      render={(props) => (
        project ?
          <span>
            {project.parent ? 
              <span><span className={classes.separator}>{' > '}</span><a href={`${process.env.REACT_APP_MY_LIXANI_URL}project/${project.parent.projectKey}`} className={classes.linkItem} >{project.parent.name}</a></span>
            : ''}
            <span className={classes.separator}>{' > '}</span><a href={`${process.env.REACT_APP_MY_LIXANI_URL}project/${project.projectKey}`} className={classes.linkItem} >{project ? project.name : ''}</a>
          </span>
        : ''
      )}  
      />
  </div>
  )
}

CrumbRouteTwo.defaultProps = {
  includeSearch: false,
}

CrumbRouteTwo.propTypes = {
  client: PropTypes.object,
  component: PropTypes.object,
  computedMatch: PropTypes.object,
  includeSearch: PropTypes.bool,
  render: PropTypes.func,
  title: PropTypes.string,
  project:PropTypes.object,
  match: PropTypes.object
}

export default CrumbRouteTwo