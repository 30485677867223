import i18n from 'i18next'
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import Config from './Config';
import { initReactI18next } from "react-i18next";

let init = {
  fallbackLng: 'en',
  otherLanguages: ['fi, et, ru, sv, ro, pl'],
  debug: true,
  ns: ['common'],
  defaultNS: 'common',
  interpolation: {
    escapeValue: false, // not needed for react!!
  },

  // react i18next special options (optional)
  react: {
    wait: true,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default'
  },

  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json?v=' + Config.BUILD_TS,
    crossDomain: true
  }
}

i18n
  .use(LanguageDetector)
  .use(HttpBackend)
  .use(initReactI18next)
  .init(init)


export default i18n;
