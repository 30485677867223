import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import AppSyncClient from 'lixani-lib-graphql'
import i18n from './i18n'
import moment from 'moment';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { createStore } from 'redux';
import { graphql, compose } from 'react-apollo';
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux';
import './index.css';
import Config from './Config'
import App from './scenes/App';
import ErrorBoundary from './ErrorBoundary';
import registerServiceWorker from './registerServiceWorker';
import rootReducer from './store/reducer';
import mutationQueryResolver from './LixaniAPI/mutationQueryResolver';
import userSettingQu from 'LixaniAPI/userSettingQu';
import userQu from 'LixaniAPI/userQu';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { useTheme } from 'components/theme';
import MainContext from './utils/mainContext';

console.log("----- config -----")
console.log(Config)
console.log("------------------")

// testing automatic update

if (Config.SENTRY_DSN) {
	Sentry.init({
		dsn: Config.SENTRY_DSN,
		release: Config.BUILD_TS,
	});
}

const graphqlErrorHandler = (graphQLErrors) => {
	console.log(graphQLErrors);
	for (let error of graphQLErrors) {
		const { message, locations, path } = error;
		Sentry.addBreadcrumb({
			category: 'graphql',
			message: `GraphQL error: ${message}`,
			data: { locations: JSON.stringify(locations), path: JSON.stringify(path) },
			level: 'error'
		});
	
	}
	Sentry.captureException(new Error("GraphQL error"));
}

// todo: Configure sentry user
//Sentry.configureScope((scope) => {
//  scope.setUser({"id": "123-123"});
//  scope.setUser({"email": "john.doe@example.com"});
//});

const store = createStore(rootReducer);

// initialize language and theme
// note: below component is vital for newest i18next to work
// it'll crash without this because i18next backend promise
// has some serious issues and will crash before getting lang files
// wait for i18n init before showing rest of the app
// newest i18next-react added hooks which are quite useful and worth this
function AppInit(props) {
	const { user, userSetting } = props;
	const [isInit, setIsInit] = useState(false);
	let themeName, localeName
	if (userSetting) {
		themeName= userSetting.theme || 'classic'
		localeName = userSetting.locale || 'fi'
	}
	else {
		themeName='classic'
		localeName='fi'
	}
	const [theme, setTheme] = useState(themeName);
	const [locale, setLocale] = useState(localeName);
	const [projectKey, setProjectKey] = useState(null);
	const [filings, setFilings] = useState({})

	useEffect(() => {
		let language;

		i18n.on('initialized', () => {
			const currentLang = i18n.language;
			try {
				language = userSetting.locale;
			} catch(error) {
				language = i18n.language;
			}

			if (currentLang !== language) {
				i18n.changeLanguage(language);
			}
			try {
				moment.locale(language);
				require('moment/locale/'+language.toLowerCase()); // eslint-disable-line no-undef
			} catch (error) {
				moment.locale('en');
			}

			if (userSetting && userSetting.theme) {
				if (userSetting.theme !== theme) {
					setTheme(userSetting.theme);
				}
			}

			setLocale(language);
			setIsInit(true);
		})
	}, []);

	useEffect(() => {
		if (isInit) {
			if (userSetting && userSetting.theme) {
				if (userSetting.theme !== theme) {
					setTheme(userSetting.theme);
				}
			}
			if (userSetting && userSetting.locale !== locale) {
				setLocale(userSetting.locale);
				try {
					const momentLocale = require('moment/locale/'+userSetting.locale.toLowerCase()); // eslint-disable-line no-undef
					moment.locale(userSetting.locale, momentLocale);
					//console.log('moment lang changed: ', userSetting.locale);
				} catch (error) {
					//console.log('moment lang error: ', error);
					moment.locale('en');
				}
			}
		}
	}, [userSetting, theme, locale]);
	

	return (
		isInit ?
			<MuiThemeProvider theme={useTheme(theme)}>
				<CssBaseline />
				<MainContext.Provider
					value = {
						{
							userSetting: userSetting,
							projectKey: projectKey,
							setProjectKey: setProjectKey,
							user: user,
							filings: filings,
							setFilings: setFilings
						}
					}
				>
					<App />
				</MainContext.Provider>
			</MuiThemeProvider>
			: <></>
	)
}

const UserSettingQu =
  graphql(userSettingQu, {
    options: {
      fetchPolicy: 'cache-and-network',
    },
    props: (props) => {
	return {
      userSetting: props.data.userSettingGet || {locale: 'fi', theme: 'classic'},
    }}
  })

const UserQu =
  graphql(userQu, {
    options: props => ({
      fetchPolicy: 'cache-and-network',
	  variables: {
		  id: (props.userSetting && props.userSetting.user) ? props.userSetting.user : ''
	  }
    }),
    props: (props) => ({
      user: props.data.userGet,
    })
  })

AppInit.defaultProps = {
	userSetting: {
		locale: 'fi',
		theme: 'classic',
	},
}

AppInit.propTypes = {
	userSetting: PropTypes.object.isRequired,
}

const AppInitApollo = compose(UserSettingQu, UserQu)(AppInit);

ReactDOM.render(
	<ErrorBoundary>
		<AppSyncClient
			graphqlEndpoint={Config.GRAPHQL_ENDPOINT}
			awsRegion={Config.AWS_REGION}
			awsAuthType={Config.AWS_AUTH_TYPE}
			ssoTokenUrl={Config.SSO_TOKEN_URL}
			ssoLoginUrl={Config.SSO_LOGIN_URL}
			filesDomain={Config.FILES_DOMAIN}
			build={Config.BUILD_TS}
			mutationQueryResolver={mutationQueryResolver}
			graphqlErrorHandler={graphqlErrorHandler}
			loading={<></>}
		>
			<Provider store={store}>
				<I18nextProvider i18n={i18n}>
					<AppInitApollo />
				</I18nextProvider>
			</Provider>
		</AppSyncClient>
	</ErrorBoundary>,
	document.getElementById('root'));
registerServiceWorker();
