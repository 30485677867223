import gql from 'graphql-tag';

import items from 'LixaniAPI/projectListItems';

const query = gql`
query projectList($limit: Int, $nextToken: String, $id: [ID], $ilve: String, $projectKey: [String]) {
  projectList(
    limit: $limit,
    nextToken: $nextToken,
    filter: {
      id: $id
      ilve: $ilve
      projectKey: $projectKey
    }
  ) {
		${items}
  }
}`;
const variables = { limit: 500 };

export default { query: query, variables: variables }
