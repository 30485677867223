import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

import MenuIcon from 'mdi-material-ui/Menu';

import NavItems from './NavItems/NavItems';
//import TimerButton from './TimerButton/TimerButton';
import { LogoNegaPng } from 'lixani-lib-data';
import Config from 'Config'

const toolbarMinHeight = 48;

const useStyles = makeStyles((theme) => ({
  root: {
		zIndex: theme.zIndex.drawer + 1,
  },
	toolbar: {
		...theme.appbarToolbarRoot,
		justifyContent: 'space-between',
		minHeight: toolbarMinHeight,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(0.5),
		[theme.breakpoints.up('md')]: {
			paddingLeft: theme.spacing(2.5),
			paddingRight: theme.spacing(1),
		},
	},
	logoButton: {
		padding: 0,
	},
	logo: {
		height: 25,
	},
	logoContainer: {
		flex: 1,
	},
	menuButton: {
		color: 'inherit',
		height: 42,
		padding: 0,
		width: 42,
	},
	sideNavPaper: {
		backgroundColor: theme.palette.background.darkPaper,
		minWidth: 240,
	},
}));

function AppBar(props) {
  const { client } = props;
  const classes = useStyles();
  const [navOpen, setNavOpen] = useState(false);

  return (
    <MuiAppBar
      className={classes.root}
      elevation={0}>
			<Toolbar className={classes.toolbar}>
				<div className={classes.logoContainer}>				
					<Button
						className={classes.logoButton}
						href={Config.MY_LIXANI_URL}
					>
						<img
							className={classes.logo}
							alt={'Lixani logo'}
							src={`data:image/png;base64,${LogoNegaPng}`}
						/>
					</Button>
					
				</div>
				{/* <TimerButton
          inRecords={inRecords}
          inRecordsLoading={inRecordsLoading}
          lastProjects={lastProjects}
        /> */}
				<IconButton
					className={classes.menuButton}
					onClick={() => setNavOpen(true)}
				>
					<MenuIcon />
				</IconButton>
				<Drawer
					anchor='right'
					classes={{ paper: classes.sideNavPaper }}
					className={classes.sideNav}
					onClose={() => setNavOpen(false)}
					open={navOpen}
				>
					<NavItems
						onCloseMenu={() => setNavOpen(false)}
						client={client}
					/>
				</Drawer>
			</Toolbar>
    </MuiAppBar>
  )
}

AppBar.propTypes = {
	client: PropTypes.object.isRequired,
};

export default AppBar;
