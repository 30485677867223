const initialState = {
	projects:[],
	/*
	titles:[
			{id:'eka',parent:null,name:'eka'},
			{id:'toka',parent:null,name:'toka'},
			{id:'kolmas',parent:null,name:'kolmas'},

		],
	*/	
	assignments:[],
};

const rootReducer = (state = initialState, action) => {
	switch(action.type) {
		case 'SAVE_PROJECTS':
			return {
				...state,
				projects:action.projects,
			}
		case 'UPDATE_STORE':
			return {
				...state,
				[action.field]:action.value,
			}
		default:	
			return state;
	}
};

export default rootReducer;
