import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
	root: {
		height: 'calc(100vh - 256px)',
	},
	content: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		flex: '1 0 100%',
		height: '100%',
		justifyContent: 'center',
	},
});

class Loading extends Component {

	renderLoading = (
		<div className={this.props.className}>
			<CircularProgress
				color={this.props.color || 'primary'}
				size={25}
			/>
		</div>
	)

	render() {
		const { classes, className, color } = this.props;

		return (
			<div className={clsx(classes.root, className)}>
				<div className={classes.content}>
					{this.renderLoading}
				</div>
			</div>
		);
	}
}

Loading.propTypes = {
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
}

export default withStyles(styles)(Loading);