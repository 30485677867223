import gql from 'graphql-tag';

export default gql`
query userSettingGet {
	userSettingGet {
		__typename
		id
		user
		locale
		theme
	}
}`;