import React from 'react';
import preval from 'preval.macro'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withApollo } from 'react-apollo';

import { makeStyles } from '@material-ui/core/styles';

import AppBar from './components/AppBar';
import { LayoutContent } from 'lixani-lib-ui';


const buildTime = preval`
	const moment = require('moment')
	module.exports = moment(new Date()).format('DDMMYY-HHmm');
`

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		minHeight: '100vh',
		width: '100%',
		zIndex: 1,
	}
}));

function Layout(props) {
	const classes = useStyles();
	const { t } = useTranslation('common');

	const { children, client } = props;
	return (
		<div className={classes.root}>
			<AppBar client={client}	/>
			<LayoutContent
				marginTop={48}
				footerText={t('footer.powered_by')+" (v. "+buildTime+")"}
				layoutStyle={{ backgroundColor: 'initial' }}
				bgStyle={{
					minHeight: `calc(100vh - ${48}px)`
				}}
			>
				{/*<div style={{"maxWidth":1024,"margin":"auto"}}>*/}
				{children}
				{/*</div>*/}
			</LayoutContent>
		</div>
	)
}

Layout.propTypes = {
	children: PropTypes.node,
	client: PropTypes.object,
};

const LayoutWithApollo = withApollo(Layout);

export default LayoutWithApollo;
