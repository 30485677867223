import React, {useEffect, useState, useContext} from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs } from 'react-breadcrumbs';
import { BrowserRouter as Router, Route,Link } from 'react-router-dom';
import { graphql, compose } from 'react-apollo';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';

import CrumbRoute from 'components/CrumbRoute/CrumbRoute';
import CrumbRouteTwo from 'components/CrumbRoute/CrumbRouteTwo';

import Layout from 'components/Layout/Layout';
import { withApollo } from 'react-apollo';
import loadComponent from 'utils/loadComponent';
import MainContext from '../utils/mainContext';
import projectQuList from 'LixaniAPI/projectQuList';

const LoadableDashboard     = loadComponent(() => import('./Dashboard'));
const LoadableInvoices      = loadComponent(() => import('./Invoices'));

const LoadableSettings      = loadComponent(() => import('./Settings'));

function App(props) {
  const { t } = useTranslation('common');
  const { client } = props;
  const {projectKey} = useContext(MainContext)
  const [project, setProject] = useState(null);
  useEffect(() => {
    const getProject = async () => {
      let projectQuery = await client.query({
          query: projectQuList.query,
          fetchPolicy: 'network-only',
          variables: {
              projectKey: [projectKey],
              ilve: 'yes'
          }
      });
      let project;
      if (projectQuery && projectQuery.data && projectQuery.data.projectList) {
          let items = projectQuery.data.projectList.items;
          project = items.find(project => project.projectKey === projectKey)
          if (project) {
            setProject(project);
          }
      }
    };
    if (projectKey) {
      getProject();
    }
  });

  return (
    <Router>
      <Layout>  
        <CrumbRouteTwo
          project={project}
          >
        </CrumbRouteTwo>
        <CrumbRoute path="/" title="Ilmoitusvelvollisuus" pathname={project && project.parent ? `/${project.parent.projectKey}` : null }  />
        <Route
          exact path="/"
          
          render={(props) =>  (<LoadableDashboard {...props} noprojectkey />)}
        >
        </Route>
        <CrumbRoute path='/:projectKey' title={ project && project.parent ? project.parent.name : ''} pathname={project && project.parent ? `/${project.parent.projectKey}` : null}/>
        <CrumbRoute path='/:projectKey' component={LoadableDashboard} title={project ? project.name : ''} />
        <CrumbRoute path='/uploadinvoices' component={LoadableInvoices} title={t('dashboard.invoices')} />
        <CrumbRoute path='/settings' component={LoadableSettings} title={t('navigation.settings')} />
      </Layout>
    </Router>
  );
}

App.propTypes = {
  client: PropTypes.object,
  match: PropTypes.object,
  projectKey: PropTypes.string
}

const appWithApollo = withApollo(App);
export default appWithApollo;
