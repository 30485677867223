const item = `
			__typename
			id
			projectKey
			name

			createdAt
			updatedAt

			startAt
			endAt

			defaultPermission {
				id
				sourceShow
				sourceUpdate
				targetShow
				targetUpdate
			}

			parent {
				id
				name
				street1
				street2
				co
				postcode
				city
				country
				explanation
				contact_first_name
				contact_last_name
				contact_street1
				contact_street2
				contact_co
				contact_postcode
				contact_city
				contact_country
				contact_email
				contact_phone

				masterProjectKey

				business_id
				business_type
				business_employment_type
				projectKey
			}
			street1
			street2
			co
			postcode
			city
			country
			explanation
			contact_first_name
			contact_last_name
			contact_street1
			contact_street2
			contact_co
			contact_postcode
			contact_city
			contact_country
			contact_email
			contact_phone

			masterProjectKey

			business_id
			business_type
			business_employment_type
			picture {
				bucket
				key
				region
			}
			pictureMust
			ilve
			idcard
			nestedidcard
			filingsInfo
			logoUploader
`;

export default item;
