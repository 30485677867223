const Config = {
  BUILD_TS: process.env.REACT_APP_BUILD_TS,
  GRAPHQL_ENDPOINT: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  AWS_REGION: process.env.REACT_APP_AWS_REGION,
  AWS_AUTH_TYPE: process.env.REACT_APP_AWS_AUTH_TYPE,
  SSO_TOKEN_URL: process.env.REACT_APP_SSO_TOKEN_URL,
  SSO_LOGIN_URL: process.env.REACT_APP_SSO_LOGIN_URL,
  MY_LIXANI_URL: process.env.REACT_APP_MY_LIXANI_URL,
  LIXANI_ADMIN_URL: process.env.REACT_APP_LIXANI_ADMIN_URL,
  LIXANI_WORK_URL: process.env.REACT_APP_LIXANI_WORK_URL,
  SSO_LOGOUT_URL: process.env.REACT_APP_SSO_LOGOUT_URL,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  S3_BUCKET: process.env.REACT_APP_S3_BUCKET,
  S3_REGION: process.env.REACT_APP_S3_REGION,
  S3_PROFILE_IMAGES_DIR: process.env.REACT_APP_S3_PROFILE_IMAGES_DIR,
  FILES_DOMAIN: process.env.REACT_APP_FILES_DOMAIN,
  UNKNOWN_PROFILE_URL: process.env.REACT_APP_UNKNOWN_PROFILE_URL,
  AWS_ACCOUNT_ID: process.env.REACT_APP_AWS_ACCOUNT_ID
}

export default Config
