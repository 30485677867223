import item from 'LixaniAPI/projectListItem';

const items=`
    items {
      ${item}
    }
    nextToken
`;

export default items;
