import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'react-breadcrumbs';
import { Route } from 'react-router-dom';

function CrumbRoute(props) {

  const {
    component: Component,
    includeSearch,
    render,
    ...restProps
  } = props
  
  return (
    <Route { ...restProps } render={ routeProps => {
      return (
      Component ? <Component { ...routeProps } key={routeProps.match.url} /> : render ? render(routeProps) : null
      )
    }} />
  )
}

CrumbRoute.defaultProps = {
  includeSearch: false,
}

CrumbRoute.propTypes = {
  component: PropTypes.object,
  includeSearch: PropTypes.bool,
  render: PropTypes.func,
  title: PropTypes.string,
}

export default CrumbRoute;
