import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import i18next from 'i18next';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.configureScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
    });
    Sentry.captureException(error);
  }

  render() {
    const reportDialog = () => Sentry.showReportDialog({ lang: i18next.language });

    if (this.state.error) {
      //render fallback UI
      return (
        <div>
          <h1>Error</h1>
          <button onClick={reportDialog}>
              Report feedback
          </button>
          <br/><br/>
          <button onClick={() => window.location.assign("/")}>
              To home
          </button>
        </div>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.array.isRequired,
  ]),
};

export default ErrorBoundary;