import React from 'react';
import Config from 'Config';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';

//import EmailIcon from 'mdi-material-ui/EmailOutline';
import HomeIcon from 'mdi-material-ui/HomeOutline';
import CalendarIcon from 'mdi-material-ui/Calendar';
import LogoutIcon from 'mdi-material-ui/ExitToApp';
//import PlusIcon from 'mdi-material-ui/Plus';
import SettingsIcon from 'mdi-material-ui/Cog';
//import UserSettingsIcon from 'mdi-material-ui/AccountMultipleOutline';
//import ProjectIcon from 'components/LightIcons/ProjectLight';
//import ProjectBankIcon from 'components/LightIcons/ProjectLight';


const useStyles = makeStyles((theme) => ({
  root: {},
	navIcon: {
		marginLeft: theme.spacing(2),
		marginRight: 0,
		color: 'rgba(255,255,255,0.87)',
	},
	navItem: {
		borderTop: '1px solid rgba(0,0,0,0.1)',
		background: theme.palette.primary.darker,
		'&:hover': {
			background: theme.palette.primary.light,
		},
		'&.active': {
			background: theme.palette.primary.light,
		}
	},
	navText: {
    ...theme.typography.fontPanton,
    fontSize: '0.87rem',
	}
}));

function NavItems(props) {
  const { client, onCloseMenu } = props;
  const classes = useStyles();
  const { t } = useTranslation('common');

  const logOutWithResetCache = (client) => {
    console.log('Logging out. Resetting apollo client')
    client.resetStore();
    onCloseMenu();
  }

  return (
    <List className={classes.root} dense disablePadding>
      <ListItem
        button
        onClick={onCloseMenu}
        className={classes.navItem}
        component={NavLink}
        disableGutters
        exact
        to="/"
      >
        <ListItemIcon className={classes.navIcon}>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            className: classes.navText,
          }}
          primary={t('navigation.home')}
        />
      </ListItem>
      {/* <ListItem
        button
        onClick={onCloseMenu}
        className={classes.navItem}
        component={NavLink}
        disableGutters
        to="/email"
      >
        <ListItemIcon className={classes.navIcon}>
          <EmailIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            className: classes.navText,
          }}
          primary={t('navigation.email')}
        />
      </ListItem> */}
      {/* <ListItem
        button
        onClick={onCloseMenu}
        className={classes.navItem}
        component={NavLink}
        disableGutters
        to="/projects"
      >
        <ListItemIcon className={classes.navIcon}>
          <ProjectIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            className: classes.navText,
          }}
          primary={t('navigation.projects')}
        />
      </ListItem> */}
      {/*<ListItem
        button
        onClick={onCloseMenu}
        className={classes.navItem}
        component={NavLink}
        disableGutters
        to="/calendar"
      >
        <ListItemIcon className={classes.navIcon}>
          <CalendarIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            className: classes.navText,
          }}
          primary={t('navigation.calendar')}
        />
        </ListItem> */}
      {/* <ListItem
        button
        onClick={onCloseMenu}
        className={classes.navItem}
        component={NavLink}
        disableGutters
        to="/usercontrol"
      >
        <ListItemIcon className={classes.navIcon}>
          <UserSettingsIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            className: classes.navText,
          }}
          primary={t('navigation.user_control')}
        />
      </ListItem> */}
      {/* <ListItem
        button
        onClick={onCloseMenu}
        className={classes.navItem}
        component={NavLink}
        disableGutters
        to="/projectbank"
      >
        <ListItemIcon className={classes.navIcon}>
          <ProjectBankIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            className: classes.navText,
          }}
          primary={t('navigation.project_bank')}
        />
      </ListItem> */}
      {/* <ListItem
        button
        onClick={onCloseMenu}
        className={classes.navItem}
        component={NavLink}
        disableGutters
        to="/addapp"
      >
        <ListItemIcon className={classes.navIcon}>
          <PlusIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            className: classes.navText,
          }}
          primary={t('navigation.add_app')}
        />
      </ListItem> */}
      <ListItem
        button
        onClick={onCloseMenu}
        className={classes.navItem}
        component={NavLink}
        disableGutters
        to="/settings"
      >
        <ListItemIcon className={classes.navIcon}>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            className: classes.navText,
          }}
          primary={t('navigation.settings')}
        />
      </ListItem>
      <ListItem
        button
        onClick={()=>logOutWithResetCache(client)}
        className={classes.navItem}
        component={'a'}
        disableGutters
        href={Config.SSO_LOGOUT_URL + encodeURIComponent(window.location)}
      >
        <ListItemIcon className={classes.navIcon}>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            className: classes.navText,
          }}
          primary={t('navigation.logout')}
        />
      </ListItem>
    </List>
  )
}

NavItems.propTypes = {
  client: PropTypes.object.isRequired,
  location: PropTypes.object,
  onCloseMenu: PropTypes.func,
}

export default NavItems;