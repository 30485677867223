import gql from 'graphql-tag';

export default gql`
query userGet($id: ID) {
	userGet(id: $id) {
    __typename
	id
    createdAt
    phone
    updatedAt
    firstName
    lastName
    dateOfBirth
    taxNumber
    whitelistEmployers
    IlveBusinessIds
	}
}`;